<template>
    <header>
        <nav
            class="mx-auto flex max-w-7xl items-center justify-between p-6 px-4 lg:px-8 z-40"
            aria-label="Global">
            <div class="flex items-center gap-x-12">
                <NuxtLink
                    :to="localePath('index')"
                    class="-m-1.5 p-1.5">
                    <span class="sr-only">Juleica</span>
                    <LogoImage />
                </NuxtLink>
                <div class="hidden lg:flex lg:gap-x-12 items-center">
                    <div
                        v-for="item in headerData"
                        :key="item.id">
                        <StrapiComponent :component="item" />
                    </div>
                    <BaseButton
                        color="secondary"
                        size="sm"
                        @click="useRouter().push(localePath('application'))">
                        Juleica beantragen
                    </BaseButton>
                </div>
            </div>
            <div class="flex lg:hidden">
                <button
                    type="button"
                    class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    @click="mobileMenuOpen = true">
                    <span class="sr-only">Open main menu</span>
                    <Icon
                        class="h-6 w-6"
                        name="heroicons:bars-3" />
                </button>
            </div>
            <div class="hidden lg:flex">
                <NuxtLink
                    v-if="!isAuthenticated"
                    :to="localePath('auth')"
                    class="text-sm font-semibold leading-6 text-gray-900">
                    Login
                </NuxtLink>
                <div v-else>
                    <Popover class="relative">
                        <PopoverButton class="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                            <Icon
                                name="heroicons:user-16-solid"
                                class="h-6 w-6 text-gray-900" />
                            <Icon
                                name="heroicons:chevron-down"
                                class="h-3 w-3 text-gray-900" />
                        </PopoverButton>

                        <Transition
                            enter-active-class="transition ease-out duration-200"
                            enter-from-class="opacity-0 translate-y-1"
                            enter-to-class="opacity-100 translate-y-0"
                            leave-active-class="transition ease-in duration-150"
                            leave-from-class="opacity-100 translate-y-0"
                            leave-to-class="opacity-0 translate-y-1">
                            <PopoverPanel class="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4 z-50">
                                <div class="w-56 shrink rounded-xl bg-white p-4 text-sm/6 font-semibold text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                                    <NuxtLink
                                        :to="localePath('/myjuleica/user')"
                                        class="block p-2 hover:text-primary">
                                        Profil
                                    </NuxtLink>
                                    <Button
                                        class="block p-2 hover:text-primary"
                                        @click="authStore.logout">
                                        Logout
                                    </Button>
                                </div>
                            </PopoverPanel>
                        </Transition>
                    </Popover>
                </div>
            </div>
        </nav>
        <Dialog
            class="lg:hidden"
            :open="mobileMenuOpen"
            @close="mobileMenuOpen = false">
            <div class="fixed inset-0 z-50" />
            <DialogPanel class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div class="flex items-center justify-between">
                    <NuxtLink
                        :to="localePath('index')"
                        class="-m-1.5 p-1.5"
                        @click="mobileMenuOpen = false">
                        <span class="sr-only">Juleica</span>
                        <LogoImage />
                    </NuxtLink>
                    <button
                        type="button"
                        class="-m-2.5 rounded-md p-2.5 text-gray-700"
                        @click="mobileMenuOpen = false">
                        <span class="sr-only">Close menu</span>
                        <Icon
                            class="h-6 w-6"
                            name="heroicons:x-mark" />
                    </button>
                </div>
                <div class="mt-6 flow-root">
                    <div class="-my-6 divide-y divide-gray-500/10">
                        <div class="space-y-2 py-6">
                            <div
                                v-for="item in headerData"
                                :key="item.id">
                                <StrapiComponent
                                    :component="item"
                                    @close-mobile-menu="mobileMenuOpen = false" />
                            </div>
                        </div>
                        <div
                            v-if="!isAuthenticated"
                            class="py-6">
                            <NuxtLink
                                :to="localePath('auth')"
                                class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                @click="mobileMenuOpen = false">
                                Login
                            </NuxtLink>
                        </div>
                        <div
                            v-else
                            class="space-y-2 py-6">
                            <NuxtLink
                                :to="localePath('/myjuleica/user')"
                                class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                @click="mobileMenuOpen = false">
                                Profil
                            </NuxtLink>
                            <Button
                                class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                @click="(mobileMenuOpen = false) && authStore.logout">
                                Logout
                            </Button>
                        </div>
                    </div>
                </div>
            </DialogPanel>
        </Dialog>
    </header>
</template>

<script setup>

import {useAuthStore} from "@/store-pinia/auth";

const navigation = [
    { name: 'Informationen', page: 'informationen', children: [
        { name: 'Qualifikation', page: 'qualifikation'},
        { name: 'Anerkennung', page: 'anerkennung'},
        { name: 'Legitimation', page: 'legitimation'},
        { name: 'Antragsstellung', page: 'antragsstellung'}
    ] },
    { name: 'Bundesländer', page: 'index' },
    { name: 'Vergünstigungen', page: 'index'},
    { name: 'Statistik', page: '/statistik' },
]

const authStore = useAuthStore()

const isAuthenticated = computed(() => authStore.isAuthenticated)

const mobileMenuOpen = ref(false)

const {find} = useStrapi()

const headerData = ref(null)

const loading = ref(true)

await useAsyncData(() => find('menu', { populate: 'deep,4' }), {
    // this will not refetch if the key exists in the payload
    getCachedData: key => nuxtApp.payload.static[key] ?? nuxtApp.payload.data[key]
}).then(response => headerData.value = response.data?.value?.data?.attributes?.Menu ?? {}).finally(() => loading.value = false)
</script>
